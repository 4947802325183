.footer {
  height: 40px;
  border-top: 1px solid #dedede;
  background-color: #f8f9fa;
}

/**
 * TODO: This can go away after release
 */
.construction {
  margin-bottom: 0px;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
}
